import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import landlord_header from "../images/landlord_header.png"

import wave from "../images/wave.svg"

const BookDemoSuccess = () => (
  <Layout>
    <SEO
      title="Book a Demo"
      description="You'll be soon seeing how Flow grows the property business of over 4,000 landlords and agents"
      image={landlord_header}
    />

    <section className="container mx-auto mt-32 p-10">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-5xl font-bold text-gray-700 leading-none">
          We've got your demo request
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          You'll be soon seeing how Flow grows the property business of over
          4,000 landlords and agents
        </p>

        <p className="font-medium text-base md:text-lg text-gray-600 mt-10">
          Continue browsing the Flow Platform
        </p>
        <br />
        <Link to="/" className="inline-block btn-primary text-center">
          More on Flow
        </Link>
      </div>
    </section>

    <img classNameName="w-full" src={wave} alt="" />

    <Footer />
  </Layout>
)

export default BookDemoSuccess
